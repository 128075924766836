import React, { Component } from "react"
import CountUp from "react-countup"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"

import styles from "./numbers.module.scss"

class Number extends Component {
  render() {
    const { percentage, text, suffix } = this.props
    return (
      <li className={styles.item} key={Math.random()}>
        <span className={styles.number}>
          <CountUp start={0} duration={3} suffix={suffix} end={percentage} />
        </span>
        <span className={styles.label}>{text}</span>
      </li>
    )
  }
}

class Numbers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }
  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })

    transition.add({
      targets: this.refs.component,
      duration: 800,
      translateY: ["104px", "0px"],
      opacity: [0, 1],
      easing: "easeInOutCirc(1, .5)",
      delay: anime.stagger(100),
    })
    this.setState({
      transition,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    } else if (!nextProps.inViewport && nextProps.enterCount < 1) {
      // if (this.state.transition && !this.state.transition.reversed) {
      //   this.state.transition.play()
      // }
    }
  }
  render() {
    const details = this.props.details
    return (
      <ul className={[styles.numbers, "wrapper"].join(" ")} ref="component">
        {details.map((item, i) => (
          <Number
            percentage={item.number}
            text={item.label}
            suffix={item.suffix}
            key={i}
          />
        ))}
      </ul>
    )
  }
}
const NumbersTransition = handleViewport(Numbers, {
  threshold: 0.2,
})
export default NumbersTransition
