import React, { Component } from "react"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"
import Link from "gatsby-link"
import styles from "./meetup.module.scss"
import ButtonAnimated from "components/ButtonAnimated"

class Meetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }

  componentDidMount() {
    var header = document.getElementById("header")
    document.addEventListener("changeColor", function() {
      if (this.props.type != null) {
        header.style.background = "white"
      }
    })

    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })
    transition
      .add({
        targets: this.refs.component.querySelectorAll(`.${styles.background}`),
        duration: 600,
        height: ["0%", "100%"],
        // opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.component.querySelectorAll(
          `.${styles.title} span > span`
        ),
        translateY: ["80px", "0px"],
        duration: 1200,
        easing: "easeInOutExpo",
        delay: anime.stagger(100),
      })
      .add(
        {
          targets: this.refs.component.querySelectorAll(`.${styles.cta} > a`),
          duration: 400,
          translateY: ["24px", "0px"],
          opacity: [0, 1],
          delay: anime.stagger(100),
        },
        "-=400"
      )
    this.setState({
      transition,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    }
  }
  render() {
    const { titleMultiple, buttons } = this.props.details

    const { type } = this.props

    return (
      <div
        id="header"
        className={[styles[type], styles.container].join(" ")}
        ref="component"
      >
        <span className={styles.background} />
        <div className="wrapper">
          {titleMultiple && (
            <header className={styles.header}>
              <h1 className={styles.title}>
                {titleMultiple.map((row, i) => (
                  <span key={i}>
                    <span>{row}</span>
                  </span>
                ))}
              </h1>
            </header>
          )}
          {buttons && (
            <div className={styles.cta}>
              {buttons.map((button, i) => (
                <ButtonAnimated
                  type={
                    button.link.includes("https") ? "external" : "transition"
                  }
                  key={i}
                  to={`${button.link}/`}
                  text={button.text}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}
const MeetupTransition = handleViewport(Meetup, {
  threshold: 0.2,
})
export default MeetupTransition
