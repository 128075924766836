import React, { Component } from "react"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"

import styles from "./intro.module.scss"

class Intro extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }
  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })

    transition
      .add({
        targets: this.refs.item.querySelector(`.${styles.line}`),
        duration: 400,
        width: ["0%", "100%"],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.item.querySelector(`.${styles.title}`),
        duration: 400,
        translateY: ["208px", "0px"],
        opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add(
        {
          targets: this.refs.item.querySelector(`.${styles.content}`),
          duration: 400,
          translateY: ["48px", "0px"],
          opacity: [0, 1],
          easing: "easeInOutCirc(1, .5)",
        },
        "-=200"
      )

    this.setState({
      transition,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    }
  }

  render() {
    const { title, content, type } = this.props
    return (
      <section
        className={[styles.intro, type ? styles[`${type}`] : null].join(" ")}
        ref="item"
      >
        <div
          className={[styles.wrapper, "wrapper", "wrapper--content"].join(" ")}
        >
          <div className={styles.inner}>
            <header className={styles.header}>
              <h2
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <span className={styles.line} />
            </header>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </section>
    )
  }
}
const IntroTransition = handleViewport(Intro, { threshold: 0.3 })
export default IntroTransition
