import React, { Component } from "react"
import { Link } from "gatsby"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"
import { navigateTo } from "gatsby"

import ArrowRightThin from "assets/icons/arrow-right-thin.svg"
// styles
import styles from "./caseitem.module.scss"

class CaseItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transitionInit: null,
    }
  }
  componentDidMount() {
    const transitionInit = anime.timeline({
      easing: "easeOutCubic",
      duration: 1700,
      // autoplay: false, //commenting this makes the animation trigger regardless of viewport
    })

    transitionInit
      .add({
        targets: this.refs.case.querySelector(`.${styles.image}`),
        opacity: [0, 1],
        duration: 400,
      })
      .add(
        {
          targets: this.refs.case.querySelector(`.${styles.reveal}`),
          height: ["100%", "0%"],
          duration: 400,
          delay: 200,
        },
        "-=200"
      )
      .add(
        {
          targets: this.refs.case.querySelector(`.${styles.background}`),
          filter: ["grayscale(1)", "grayscale(0)"],
          duration: 400,
        },
        "-=200"
      )
      .add(
        {
          targets: this.refs.case.querySelectorAll(
            `.${styles.title} span > span`
          ),
          translateY: ["172px", "0px"],
          duration: 1200,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        "-=500"
      )

    this.setState({
      transitionInit,
    })
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.inViewport && nextProps.enterCount === 1) {
  //     this.state.transitionInit.play()
  //   }
  // }

  //below does not load images that are ALREADY in viewport.
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.inViewport && nextProps.enterCount === 1) {
  //     if (!this.state.transitionInit.reversed) {
  //       this.state.transitionInit.play()
  //     }
  //   }
  // }

  // click = slug => {
  //   if (!this.state.transitionInit.reversed) {
  //     this.state.transitionInit.reverse()
  //     this.state.transitionInit.play()
  //   }
  //   setTimeout(() => {
  //     navigateTo(slug)
  //   }, 1700)
  // }
  render() {
    const { hero, overview, slug } = this.props.case
    const casePrefix = this.props.casePrefix
    // console.log("hero.image.src", hero.image.src)
    // console.log("hero", overview.title)
    return (
      <Link
        to={`/${casePrefix}/${slug}/`}
        className={styles.container}
        ref="case"
      >
        <div className={styles.image}>
          <span className={styles.reveal} />
          {hero.image.src && hero.image.src.localFile && (
            <Image
              fluid={hero.image.src.localFile.childImageSharp.fluid}
              className={styles.background}
              alt={hero.image.alt}
              title={hero.image.title}
            />
          )}
        </div>
        <header className={styles.header}>
          <span className={styles.title}>
            {overview.title.map((title, i) => (
              <span key={i}>
                <span>{title}</span>
              </span>
            ))}
          </span>
          <span className={styles.subtitle}>
            <span dangerouslySetInnerHTML={{ __html: overview.subtitle }} />
          </span>
          <ArrowRightThin className={styles.arrow} />
        </header>
      </Link>
    )
  }
}

const CaseItemTransition = handleViewport(CaseItem, {
  threshold: 0.4,
})
export default CaseItemTransition
