import React, { Component } from "react"
import youtubeVideo from "assets/video/cedric-gedicht-franciscus-vlietland.mp4";

import styles from "./casevideo.module.scss";

class CaseVideo extends Component {
  render() {
    return (
      <div className={[styles.wrapper, "wrapper", "wrapper--content"].join(" ")}>
        <video src={youtubeVideo} width="800" height="400" controls />
      </div>
    )
  }
}

export default CaseVideo;