import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import ContentBlock from "components/ContentBlock"
import CaseHero from "components/CaseHero"
import Numbers from "components/Numbers"
import ImageGrid from "components/ImageGrid"
import ImageGridTwo from "components/ImageGridTwo"
import ImageGridThree from "components/ImageGridThree"
import Intro from "components/Intro"
import Meetup from "components/Meetup"
import CaseRelated from "components/CaseRelated"
import CaseVideo from "components/CaseVideo"

const CaseTemplate = props => {
  const {
    hero,
    blocks,
    cases,
    cta,
    intro,
    numbers,
    seo,
  } = props.data.wordpressCase

  let showvideo = false;
  if(props.location.pathname === '/case/franciscus-gasthuis-vlietland/') {
    showvideo = true;
  }

  return (
    <Layout location={props.location} navigation>
      <SEO seo={seo} />
      <CaseHero details={hero} />
      {numbers && <Numbers details={numbers} />}
      <Intro
        title={intro.title}
        content={intro.content}
        type={!numbers && "margin"}
      />
      {showvideo && (
        <CaseVideo />
      )}
      {blocks.map((item, i) => {
        switch (item.type) {
          case "content":
            return (
              <ContentBlock
                title={item.block.title}
                content={item.block.content}
                link={item.block.link}
              />
            )
          case "imagesLandscape":
            return <ImageGrid images={item.block.images} />
          case "imagesPortrait":
            return <ImageGridTwo images={item.block.images} />
          case "imagesPortraitTwo":
            return <ImageGridThree images={item.block.images} />
          default:
        }
      })}
      <Meetup details={cta} />
      {cases && (
        <CaseRelated
          details={cases}
          prefix={props.data.site.siteMetadata.casePrefix}
        />
      )}
    </Layout>
  )
}

export default CaseTemplate

export const caseQuery = graphql`
  query CaseByID($id: String!) {
    site {
      siteMetadata {
        title
        author
        casePrefix
      }
    }
    wordpressCase(id: { eq: $id }) {
      id
      slug
      overview {
        title
        subtitle
      }
      hero {
        title
        titleMobile
        author
        image {
          title
          src {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1921) {
                  srcSet
                }
              }
            }
          }
        }
      }
      blocks {
        type
        block {
          content
          images {
            alt
            title
            src {
              localFile {
                childImageSharp {
                  fluid(
                    quality: 100
                    srcSetBreakpoints: [320, 400, 768, 1024, 1280, 1921]
                    maxWidth: 1921
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          title
        }
      }
      cases {
        slug
        overview {
          title
          subtitle
        }
        hero {
          image {
            title
            src {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      cta {
        buttons {
          link
          text
        }
        title
        titleMultiple
      }
      intro {
        title
        content
      }
      seo {
        title
        siteName
        description
        image {
          src {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 853, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
