import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"

import styles from "./case-hero.module.scss"
// TODO: Change <img> to <picture>
class CaseHero extends Component {
  componentDidMount() {
    const tl = anime.timeline({
      easing: "easeOutCubic",
      duration: 1500,
    })

    tl.add(
      {
        targets: this.refs.hero.querySelector(`.${styles.image}`),
        opacity: [0, 1],
        duration: 400,
      },
      100
    )
      .add({
        targets: this.refs.hero.querySelector(`.${styles.background}`),
        height: ["0%", "100%"],
        duration: 1000,
      })
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.reveal}`),
          height: ["100%", "0%"],
          duration: 400,
          delay: 200,
        },
        "-=200"
      )
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.title} span > span`
          ),
          translateY: ["172px", "0px"],
          duration: 1200,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        "-=500"
      )
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.author} span > span`
          ),
          translateY: ["30px", "0px"],
          duration: 800,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        "-=800"
      )
  }

  render() {
    const { title, titleMobile, image, author } = this.props.details

    let featuredImage = false

    if (image.src !== null && image.src.localFile) {
      featuredImage = image.src.localFile.childImageSharp.fluid
    }

    return (
      <section className={styles.hero} ref="hero">
        <div className={[styles.wrapper, "wrapper"].join(" ")}>
          <div className={styles.image}>
            <span className={styles.reveal} />
            <Image
              fluid={featuredImage}
              className={styles.background}
              alt={image.alt}
              title={image.title}
            />
          </div>
          <header className={styles.header}>
            <h1 className={styles.title}>
              {title.map((row, i) => (
                <span key={i}>
                  <span>{row}</span>
                </span>
              ))}
            </h1>
            <span className={styles.author}>
              <span>
                <span>Door</span>
              </span>
              <span>
                <span>{author.label}</span>
              </span>
            </span>
          </header>
        </div>
      </section>
    )
  }
}

export default CaseHero
