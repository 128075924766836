import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"
// components
import CaseItem from "components/CaseItem"

import styles from "./caserelated.module.scss"
// TODO: Change <img> to <picture>
class CaseRelated extends Component {
  componentDidMount() {
    const tl = anime.timeline({
      easing: "easeOutCubic",
      duration: 1500,
    })

    tl.add(
      {
        targets: this.refs.hero.querySelector(`.${styles.caseItem} img`),
        height: ["0px", "376px"],
        duration: 4000,
        easing: "easeInOutExpo",
        delay: anime.stagger(800),
      },
      "-=800"
    )
    // .add(
    //   {
    //     targets: this.refs.hero.querySelector(`.${styles.reveal}`),
    //     height: ["100%", "0%"],
    //     duration: 400,
    //     delay: 200,
    //   },
    //   "-=200"
    // )
    // .add(
    //   {
    //     targets: this.refs.hero.querySelectorAll(
    //       `.${styles.title} span > span`
    //     ),
    //     translateY: ["172px", "0px"],
    //     duration: 1200,
    //     easing: "easeInOutExpo",
    //     delay: anime.stagger(100),
    //   },
    //   "-=500"
    // )
    // .add(
    //   {
    //     targets: this.refs.hero.querySelectorAll(
    //       `.${styles.author} span > span`
    //     ),
    //     translateY: ["30px", "0px"],
    //     duration: 800,
    //     easing: "easeInOutExpo",
    //     delay: anime.stagger(100),
    //   },
    //   "-=800"
    // )
  }

  render() {
    const CasesRelative = this.props.details
    const casePrefix = this.props.prefix
    // console.log("this.props", this.props)

    return (
      <section className={styles.container} ref="hero">
        <div className={"wrapper"}>
          <div className={styles.cases}>
            {CasesRelative.map(
              (item, i) => (
                <CaseItem key={i} case={item} casePrefix={casePrefix} />
              )
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default CaseRelated
